<template>
  <div class="amount-chart">
    <loading-spinner v-if="isLoading" />
    <bar-chart
      v-else
      :chart-data="chartData"
      :chart-options="chartOptions"
    />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import BarChart from '@/components/GlobalComponents/Charts/BarChart'
import { mapGetters } from 'vuex'
import getWeekNumber from '@/helpers/getWeekNumber'

export default {
  name: 'AmountChart',
  components: {
    LoadingSpinner,
    BarChart
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#FD6412',
            barThickness: 25, // sirka farebnych stlpcov v px
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: { // nazov grafu
            display: true,
            align: 'start',
            padding: {
              top: 0,
              bottom: 34
            },
            color: '#000000',
            font: {
              family: 'Helvetica Neue LT Pro',
              size: 20,
              weight: '500',
              lineHeight: '32px'
            },
            text: this.$t('kpi.charts.amount.title')
          },
          legend: { // legenda
            display: false // hide legend on top
          },
          tooltip: {
            titleFont: {
              family: 'Helvetica Neue LT Pro'
            },
            bodyFont: {
              family: 'Helvetica Neue LT Pro'
            }
          }
        },
        scales: {
          x: {
            grid: { // x axis grid
              display: false
            },
            ticks: { // x axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              }
            }
          },
          y: {
            grid: { // y axis grid
              color: 'rgba(228, 228, 228, 0.2)',
              borderColor: ''
            },
            ticks: { // y axis labels
              color: '#7D7D7D',
              font: {
                family: 'Helvetica Neue LT Pro',
                size: 12,
                weight: '400',
                lineHeight: '16px'
              },
              stepSize: 2 // y axis label increment
            }
          }
        }
      },
      interval: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getWeek: 'KpiDateRangeFilter/getWeek',
      getUserData: 'User/getUserData'
    })
  },
  watch: {
    getWeek (newVal, oldVal) {
      this.getAmountData()
    }
  },
  created () {
    this.getAmountData()
    this.interval = setInterval(async () => {
      await Promise.allSettled([this.resetData(), this.getAmountData()])
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    getWeekNumber,
    async getAmountData () {
      try {
        this.isLoading = true
        const { data } = await this.$axios.get('/machine-errors/?select=createdAt')

        // convert time
        const convertedDataTime = data.map(a => ({
          createdAt: new Date(new Date(a.createdAt).setHours(0, 0, 0, 0))
        }))

        // labels
        if (this.getWeek === 1) {
          this.chartData.labels.length = 0
          this.chartData.labels.push(1)
        } else if (this.getWeek === 2) {
          this.chartData.labels.length = 0
          this.chartData.labels.push(1, 2)
        } else if (this.getWeek === 3) {
          this.chartData.labels.length = 0
          this.chartData.labels.push(1, 2, 3)
        } else if (this.getWeek === 4) {
          this.chartData.labels.length = 0
          this.chartData.labels.push(1, 2, 3, 4)
        } else {
          this.chartData.labels.length = 0
          this.chartData.labels.push(1, 2, 3, 4, 5)
        }

        // STARA VERZIA, rolling tyzden (aktualny den - 6 dni)
        // get dates - bez ohladu na cas
        // const todayDate = new Date()
        // const today0 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
        // const today6 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 6)

        // const today7 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7)
        // const today13 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 13)

        // const today14 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 14)
        // const today21 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 21)

        // const today22 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 22)
        // const today29 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 29)

        // const today30 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 30)
        // const today37 = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 37)

        // count tasks for each week
        // const week1 = convertedDataTime.filter(obj => {
        //   if (obj.createdAt <= today0 && obj.createdAt >= today6) {
        //     return true
        //   } return false
        // }).length

        // const week2 = convertedDataTime.filter(obj => {
        //   if (obj.createdAt <= today7 && obj.createdAt >= today13) {
        //     return true
        //   } return false
        // }).length

        // const week3 = convertedDataTime.filter(obj => {
        //   if (obj.createdAt <= today14 && obj.createdAt >= today21) {
        //     return true
        //   } return false
        // }).length

        // const week4 = convertedDataTime.filter(obj => {
        //   if (obj.createdAt <= today22 && obj.createdAt >= today29) {
        //     return true
        //   } return false
        // }).length

        // const week5 = convertedDataTime.filter(obj => {
        //   if (obj.createdAt <= today30 && obj.createdAt >= today37) {
        //     return true
        //   } return false
        // }).length

        // NOVA VERZIA, tyzden od pondelka do nedele, triedenie podla cisla tyzdna
        const thisWeekNumber = getWeekNumber(new Date())
        // console.log(thisWeekNumber)
        const week1 = convertedDataTime.filter(obj => {
          if (getWeekNumber(obj.createdAt) === thisWeekNumber) {
            return true
          } return false
        }).length

        const week2 = convertedDataTime.filter(obj => {
          if (getWeekNumber(obj.createdAt) === thisWeekNumber - 1) {
            return true
          } return false
        }).length

        const week3 = convertedDataTime.filter(obj => {
          if (getWeekNumber(obj.createdAt) === thisWeekNumber - 2) {
            return true
          } return false
        }).length

        const week4 = convertedDataTime.filter(obj => {
          if (getWeekNumber(obj.createdAt) === thisWeekNumber - 3) {
            return true
          } return false
        }).length

        const week5 = convertedDataTime.filter(obj => {
          if (getWeekNumber(obj.createdAt) === thisWeekNumber - 4) {
            return true
          } return false
        }).length

        // send data to chart
        if (this.getWeek >= 1) {
          this.chartData.datasets[0].data.length = 0
          this.chartData.datasets[0].data.push(week1)
        }

        if (this.getWeek >= 2) {
          this.chartData.datasets[0].data.length = 0
          this.chartData.datasets[0].data.push(
            week1,
            week2
          )
        }

        if (this.getWeek >= 3) {
          this.chartData.datasets[0].data.length = 0
          this.chartData.datasets[0].data.push(
            week1,
            week2,
            week3
          )
        }

        if (this.getWeek >= 4) {
          this.chartData.datasets[0].data.length = 0
          this.chartData.datasets[0].data.push(
            week1,
            week2,
            week3,
            week4
          )
        }

        if (this.getWeek >= 5) {
          this.chartData.datasets[0].data.length = 0
          this.chartData.datasets[0].data.push(
            week1,
            week2,
            week3,
            week4,
            week5
          )
        }

        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },
    resetData () {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
    }
  }
}
</script>

<style lang="scss" scoped>
div.amount-chart {
  background-color: $primary-white;
  padding: 32px;
  width: 100%;
}
</style>
