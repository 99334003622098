<template>
  <div class="kpi-basic-wrapper">
    <div class="kpi-basic-charts">
      <div class="aligner">
        <amount-chart />
        <downtime-chart class="marginator" />
        <meantime-chart class="marginator" />
        <paused-tasks-chart class="marginator" />
        <stopped-tasks-chart class="marginator" />
      </div>
    </div>
  </div>
</template>

<script>
import AmountChart from './AmountChart.vue'
import DowntimeChart from './DowntimeChart.vue'
import MeantimeChart from './MeantimeChart.vue'
import PausedTasksChart from './PausedTasksChart.vue'
import StoppedTasksChart from './StoppedTasksChart.vue'

export default {
  name: 'BasicKpi',
  components: {
    AmountChart,
    DowntimeChart,
    MeantimeChart,
    PausedTasksChart,
    StoppedTasksChart
  }
}
</script>

<style lang="scss" scoped>
div.kpi-basic-wrapper {
  width: 100%;

  div.kpi-basic-charts {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.aligner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1108px;

      .marginator {
        margin-top: 32px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.kpi-basic-wrapper {
    div.kpi-basic-charts {
      padding: 0;

      div.aligner {
        .marginator {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
